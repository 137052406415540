<template>
  <div
      v-if="fetchedSong"
      :class="
      `${embedMode ? 'embed-mode' : ''} ${isOnPrintMode ? 'on-print-mode' : ''}`
    "
  >
    <div v-if="showTitle" class="mb-3">
      <div v-if="false" class="export-rule"></div>
      <v-row>
        <v-col cols="12" :sm="isOnPrintMode ? 12 : 6" class="song-header">
          <div class="song-print-key text-center" v-if="isOnPrintMode">
            <v-avatar color="primary" class="white--text key">
              {{ selectedKey }}
            </v-avatar>
            <div class="body-1" v-if="selectedCapo > 0">
              CAPO +{{ selectedCapo }}
            </div>
          </div>
          <h1>
            {{ fetchedSong.title }}
          </h1>

          <div v-if="fetchedSong.author || fetchedSong.copyright">
            <span
                class="text-truncate d-inline-block max-width-100"
                v-if="fetchedSong.author && fetchedSong.author.length > 0"
            >
              {{ fetchedSong.author }}
            </span>

            <span
                class="hidden-screen-only caption text-center text-truncate"
                v-if="fetchedSong.copyright && fetchedSong.copyright.length > 0"
            >
              - {{ fetchedSong.copyright }}
            </span>
          </div>
<!--          <div class="font-italic watermark" v-if="isOnPrintMode">-->
<!--            Généré avec ADF - www.autour-du-feu.fr-->
<!--          </div>-->
        </v-col>

        <v-col
            v-if="!isOnPrintMode"
            cols="12"
            sm="6"
            class="text-right d-flex d-print-none flex-column justify-center align-end"
        >
          <div>
            Clé de {{ selectedKey }} Capo {{ selectedCapo }}
            <span v-if="fetchedSong.favoriteKey == selectedKey">
              <v-icon color="yellow darken-1">mdi-star</v-icon>
            </span>
          </div>

          <v-toolbar
              flat
              dense
              class="pt-0 mr-n1 d-print-none"
              id="song-actions"
          >
            <v-menu
                v-if="$vuetify.breakpoint.mdAndDown"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    fab
                    icon
                    color="default"
                    class=""
                >
                  <v-icon>
                    mdi-cog-outline
                  </v-icon>
                </v-btn>
              </template>
              <adf-songs-key-selector
                  v-if="selectedKey"
                  :keyIsLocked="keyIsLocked"
                  :selectedKey.sync="selectedKey"
                  :selectedCapo.sync="selectedCapo"
                  :fetchedSong="fetchedSong"
                  :updateSongPreferences="updateSongPreferences"
              />
            </v-menu>

            <v-btn
                fab
                icon
                :to="{ name: 'songs_edit', params: { song_id: fetchedSong.id } }"
                v-if="currentUserIsCreator"
            >
              <v-icon>
                mdi-file-document-edit-outline
              </v-icon>
            </v-btn>

            <v-btn
                fab
                icon
                @click="destroy(fetchedSong.id)"
                v-if="currentUserIsCreator"
            >
              <v-icon>
                mdi-delete-outline
              </v-icon>
            </v-btn>

            <v-btn @click="print" fab icon color="default" class="">
              <v-icon>
                mdi-file-pdf-box
              </v-icon>
            </v-btn>
            
            <a :href="
                `/exports/chants/${fetchedSong.id}.chord`
              " download>
              <v-btn fab icon color="default" class="">
                <v-icon>
                  mdi-music-box
                </v-icon>
              </v-btn>
            </a>
            <a
                :href="
                `onsong://autour-du-feu.fr/exports/chants/${fetchedSong.id}`
              "
            >
              <v-btn fab icon color="default" class="">
                <img src="@assets/images/onsong.svg"/>
              </v-btn>
            </a>
            <v-btn
                v-if="embedMode"
                target="_blank"
                :to="{ name: 'songs_show', params: { song_id: fetchedSong.id } }"
                fab
                icon
                color="default"
            >
              <v-icon>
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
    </div>
    <v-row class="mt-3">
      <v-col cols="12" :lg="isOnPrintMode ? 12 : 8" class="pt-0">
        <v-card :flat="isOnPrintMode" class="print-flat">
          <v-tabs
              id="song-tab"
              v-model="tab"
              class="d-print-none"
              active-class="active-tab"
              v-if="!isOnPrintMode"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab :href="`#chords`" v-if="$vuetify.breakpoint.smAndUp">
              <!-- <v-icon left>mdi-text-subject</v-icon> -->
              ACCORDS
            </v-tab>
            <v-tab :href="`#lyrics`">
              <!-- <v-icon left>mdi-text-subject</v-icon> -->
              PAROLES
            </v-tab>
            <v-tab :href="`#sharedLinks`">
              <!-- <v-icon left>mdi-library-video</v-icon> -->
              AUDIO / VIDEO
            </v-tab>
          </v-tabs>

          <v-tabs-items

              v-model="tab"
              id="song-tab-contents"
              :class="`${isOnPrintMode ? 'print-mode' : ''}`"
          >
            <v-tab-item value="chords">
              <v-flex xs12 style="background-color:white; width:1000px;">
                <v-card flat>
                  <v-card-text>
                    <div class="song-content d-sm-block d-none d-print-block">
                      <section
                          :class="`song-section`"
                          ref="songContent"
                          v-for="(section, i) in fetchedSong.sections"
                          :key="`section-${i}`"
                      >
                        <!--                        <div-->
                        <!--                            v-if="needsAPageBreak(section, i)"-->
                        <!--                            class="html2pdf__page-break page-break"-->
                        <!--                        ></div>-->
                        <div
                            class="section-name"
                            v-if="section.name && section.name.length > 0"
                        >
                          {{ section.name }}
                        </div>

                        <div
                            :ref="`song-lines`"
                            :class="
                          `song-line ${
                            line.notes && line.notes.length > 0
                              ? 'with-notes'
                              : ''
                          }`
                        "
                            :key="`line-${j}`"
                            v-for="(line, j) in section.lines"
                        >
                          <div
                              class="line-notes"
                              v-if="line.notes && line.notes.length > 0"
                          >
                          <span
                              class="note"
                              v-bind:style="{
                              left: `${note.offset * noteOffsetMultiplier}px`,
                            }"
                              v-for="note in line.notes"
                              :key="note.id"
                          >
                            {{ note.note }}
                          </span>
                          </div>
                          {{ line.content }}
                        </div>
                      </section>
                    </div>
                    <div class="d-block d-sm-none d-print-none">
                      <p>
                        Cette fonctionnalité n'est pas encore disponible sur cette
                        taille d'écran, connectez-vous avec un ordinateur ou une
                        tablette pour visualiser correctement cette partition
                      </p>
                    </div>

                  </v-card-text>
                </v-card>
              </v-flex>
            </v-tab-item>

            <v-tab-item value="lyrics">
              <v-card flat v-if="!isOnPrintMode">
                <v-card-text>
                  <div class="song-content">
                    <div
                        class="song-section"
                        v-for="(section, i) in fetchedSong.sections"
                        :key="`section-${i}`"
                    >
                      <div
                          class="section-name"
                          v-if="section.name && section.name.length > 0"
                      >
                        {{ section.name }}
                      </div>
                      <div
                          :ref="`song-lines`"
                          :class="
                          `song-line ${
                            line.notes && line.notes.length > 0
                              ? 'with-notes'
                              : ''
                          }`
                        "
                          :key="`line-${j}`"
                          v-for="(line, j) in section.lines"
                      >
                        <!-- <div class="line-notes" v-if="line.notes && line.notes.length > 0">
                            <span class="note"
                              v-bind:style="{left: `${note.offset * noteOffsetMultiplier}px`}"
                              v-for="note in line.notes" :key="note.id">
                              {{note.note}}
                            </span>
                          </div> -->
                        {{ line.content }}
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item value="sharedLinks" eager>
              <v-card flat v-if="!isOnPrintMode && currentUser">
                <v-card-text>
                  <v-list-item class="pa-0">
                    <v-list-item-avatar color="grey" class="mb-auto mt-4">
                      <v-avatar>
                        <img :src="currentUser.avatar.thumb" alt="avatar"/>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class="pb-0">
                      <v-list-item-title class="mb-n4">
                        <v-textarea
                            autocomplete="off"
                            v-model="post.content"
                            auto-grow
                            outlined
                            rows="3"
                            placeholder="Postez ici un message, l'URL d'une vidéo YouTube ou d'un titre Soundcloud"
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item class="ml-14 pa-0 attach-zone" v-if="false">
                    <adf-medias-form @medias:file-added="attachMedia"/>
                  </v-list-item>

                  <v-list-item class="pa-0">
                    <v-btn
                        color="primary"
                        class="ml-auto"
                        rounded
                        @click="postsCreate"
                        is-loading="isSavingActivity"
                    >
                      <v-icon left>
                        mdi-check
                      </v-icon>
                      PUBLIER
                    </v-btn>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <div v-show="!isOnPrintMode && tab == 'sharedLinks'">
          <adf-activity
              v-for="(activity, i) in activities"
              @destroyed="onActivityDestroyed(activity, i)"
              :activity="activity"
              :key="i"
          />
        </div>

        <adf-loader
            position="relative"
            align="center"
            v-show="!isOnPrintMode && isLoadingActivities && tab == 'sharedLinks'"
        >
        </adf-loader>
      </v-col>

      <v-col
          cols="12"
          lg="4"
          class="pt-0"
          v-if="$vuetify.breakpoint.lgAndUp && !isOnPrintMode"
      >
        <adf-songs-key-selector
            v-if="selectedKey"
            :keyIsLocked="keyIsLocked"
            :selectedKey.sync="selectedKey"
            :selectedCapo.sync="selectedCapo"
            :fetchedSong="fetchedSong"
            :updateSongPreferences="updateSongPreferences"
        />
        <div class="mt-5 caption text-center" v-if="fetchedSong.createdBy">
          Ajouté par {{ fetchedSong.createdBy.fullName }}
          <v-avatar size="36px">
            <img
                v-if="fetchedSong.createdBy.avatar.thumb"
                alt="Avatar"
                :src="fetchedSong.createdBy.avatar.thumb"
            />
            <v-icon v-else>mdi-user</v-icon>
          </v-avatar>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import debounce from "debounce";
import {mapState, mapGetters} from "vuex";
import {decamelizeKeys} from "xcase";
import adfActivity from "../activities/activity.vue";
import adfUserSongPrefertence from "../user_song_preferences/show.vue";
import {EventBus} from "../../event-bus.js";
import {transpose} from "chord-transposer";
import adfMediasForm from "../medias/form.vue";
import adfSongsKeySelector from "./key-selector.vue";
import {songsShow} from "../../api/songs";

export default {
  name: "adf-songs-show",
  components: {
    adfActivity,
    adfUserSongPrefertence,
    adfMediasForm,
    adfSongsKeySelector,
  },
  props: {
    song: {
      type: Object,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    embedMode: {
      type: Boolean,
    },
    enablePrintMode: {
      type: Boolean,
    },
    keyIsLocked: {
      type: Boolean,
    },
  },
  data() {
    return {
      tab: "chords",
      noteOffsetMultiplier: 7,
      isFetchingSong: false,
      isInitializingSong: false,
      isLoadingActivities: false,
      isSavingActivity: false,
      fetchedSong: this.song,
      selectedKey: null,
      selectedCapo: null,
      page: 0,
      activities: [],
      post: this.blankPost(),
      isOnPrintMode: false,
    };
  },
  beforeMount() {
    this.setupPrintCallbacks();
  },
  beforeDestroy() {
    delete window.onbeforeprint;
    delete window.onafterprint;
  },
  methods: {
    needsAPageBreak(section, i) {
      if (!this.isOnPrintMode) return false;
      const pageHeight = 1060;
      //
      // console.log(`----SECTION #${i} ${section.name}-----`);
      // // Calculate number of lines of the sections before
      // const beforeSections = this.fetchedSong.sections.slice(0, i);
      // let beforeSectionsHeight =
      //   beforeSections
      //     .map((section) => this.getSectionHeight(section))
      //     .reduce(
      //       (sectionHeight, totalHeight) => sectionHeight + totalHeight,
      //       0
      //     ) + 95;
      //
      // let currentSectionHeight = this.getSectionHeight(section, true);
      //
      //
      // const beforeSectionPage = Math.floor(beforeSectionsHeight / pageHeight);
      // const extraPageBreakSpace = beforeSectionPage * 10;
      // const currentSectionPage = Math.floor(
      //   (beforeSectionsHeight + extraPageBreakSpace + currentSectionHeight) /
      //     pageHeight
      // );
      // const sectionNeedsAPageBreak = beforeSectionPage < currentSectionPage;
      //
      // console.log(
      //   "totalSectionHeight",
      //   beforeSectionsHeight + currentSectionHeight,
      //   beforeSectionsHeight,
      //   currentSectionHeight
      // );
      // // console.log("(beforeSectionsHeight + currentSectionHeight) / pageHeight", (beforeSectionsHeight + currentSectionHeight) / pageHeight);
      // console.log(
      //   "beforeSectionsHeight page",
      //   Math.floor(beforeSectionsHeight / pageHeight)
      // );
      // console.log(
      //   "currentSection page",
      //   Math.floor((beforeSectionsHeight + currentSectionHeight) / pageHeight)
      // );
      // console.log("currentHeight", currentSectionHeight);
      // console.log("beforeSectionsHeight", beforeSectionsHeight);
      // console.log("sectionNeedsAPageBreak", sectionNeedsAPageBreak);
      // console.log("---------");
      // // Figure out if it goes into space or if we need to split page ?
      // return sectionNeedsAPageBreak;
    },
    getSectionHeight(section, debug) {
      const numberOfLinesWithNotes = section.lines.filter(
          (line) => !!line.notes.length
      );
      const numberOfLinesWithoutNotes = section.lines.filter(
          (line) =>
              line.notes.length == 0 && !!line.content && line.content.length > 0
      );
      const lineWithNotesHeight = 23 + 23 + 15;
      const lineWithoutNotes = 23;

      // Estimate the height of elements
      const totalHeightOfLinesWithNotes =
          numberOfLinesWithNotes.length * lineWithNotesHeight;
      const totalHeightOflineWithoutNotes =
          numberOfLinesWithoutNotes.length * lineWithoutNotes;
      if (debug) {
        // console.log("numberOfLinesWithNotes", numberOfLinesWithNotes.length);
        // console.log("totalHeightOfLinesWithNotes", totalHeightOfLinesWithNotes);
        // console.log("numberOfLinesWithoutNotes", numberOfLinesWithoutNotes);
        // console.log("totalHeightOflineWithoutNotes", totalHeightOflineWithoutNotes);
      }
      return totalHeightOfLinesWithNotes + totalHeightOflineWithoutNotes + 70;
    },
    setupPrintCallbacks() {
      window.onbeforeprint = (() => {
        this.isOnPrintMode = true;
        this.$nextTick(() => {
          this.resolveNoteOffset();
        })
      }).bind(this);

      window.onafterprint = (() => {
        this.isOnPrintMode = false;
        setTimeout(() => {
          this.resolveNoteOffset();
        });
      }).bind(this);
    },
    print() {
      this.isOnPrintMode = true;
      this.$nextTick(function () {
        this.resolveNoteOffset();
        setTimeout(() => {
          window.print();
        }, 500);
      });
      console.log("----> print");
      // let routeData = this.$router.resolve({
      //   name: "songs_export",
      //   query: { song_id: this.fetchedSong.id },
      // });
      // console.log("routeData", routeData);
      // window.open(`/#/chants/${this.fetchedSong.id}/exporter`, "_blank");
    },
    destroy(songId) {
      let url = `/api/chants/${songId}.json`;
      this.$http.delete(url).then(() => {
        this.$router.push({
          name: "dashboard",
        });
      });
    },
    blankPost() {
      return {
        content: "",
        isDraft: true,
        recipientId: this.song?.id || this.$route.params.song_id,
        recipientType: "Song",
        visibility: "public",
        noMoreActivities: false,
        mediasIds: [],
      };
    },
    onActivityDestroyed(activity, i) {
      this.activities.splice(i, 1);
    },
    fetchSong(key, capo) {
      let songId = this.$route.params.song_id || this.song.id;
      if (!this.isFetchingSong && songId) {
        this.isFetchingSong = true;
        songsShow(songId, key, capo).then((fetchedSong) => {
          this.isFetchingSong = false;
          this.fetchedSong = fetchedSong;
          this.$store.commit("refreshRouteMetas", {
            meta: {
              title: this.fetchedSong.title,
            },
            route: this.$route,
          });
          this.selectedKey = this.fetchedSong.lastKey || this.fetchedSong.key;
          this.selectedCapo = this.fetchedSong.lastCapo || 0;
          this.isFetchingSong = false;
          this.scroll();

          // Unlogged visitors are allowed to change capo locally only
          if (!this.currentUser && !!capo) {
            this.selectedCapo = capo;
          }

          this.transposeFetchedSong();
          this.post = this.blankPost();
        });
      }

      // return new Promise((resolve, reject) => {
      //   if (!this.isFetchingSong) {
      //     this.isFetchingSong = true;
      //     let songId = this.$route.params.song_id || this.song.id;
      //     if (songId) {
      //       var url = `/api/chants/${songId}`;
      //       if (key) {
      //         url = `${url}/cle/${encodeURIComponent(key)}`;
      //         if (capo >= 0) {
      //           url = `${url}/capo/${capo}`;
      //         }
      //       }
      //       this.$http.get(`${url}.json`).then((response) => {
      //         // console.log("ooo", response.body);
      //         this.fetchedSong = response.data;
      //         this.$store.commit("refreshRouteMetas", {
      //           meta: {
      //             title: this.fetchedSong.title,
      //           },
      //           route: this.$route,
      //         });
      //         this.selectedKey =
      //           this.fetchedSong.lastKey || this.fetchedSong.key;
      //         this.selectedCapo = this.fetchedSong.lastCapo || 0;
      //         this.isFetchingSong = false;
      //         this.scroll();

      //         this.transposeFetchedSong();
      //         this.post = this.blankPost();
      //         resolve(this.fetchedSong);
      //       });
      //     }
      //   } else {
      //     resolve();
      //   }
      // });
    },
    transposeFetchedSong() {
      console.log("this.fetchedSong", this.selectedKey);
      const originalKey = this.fetchedSong.key
          .split("/")[0]
          .replace("A#", "Bb");
      const transposedKey = this.selectedKey.split("/")[0].replace("A#", "Bb");
      const capo = this.selectedCapo;

      console.log(
          `Transposing from ${originalKey} to ${transposedKey} with capo ${capo}`
      );
      const newSections = this.fetchedSong.sections;
      newSections.forEach((s) => {
        s.lines.forEach((l) => {
          l.notes.forEach((n) => {
            n.note = transpose(n.note)
                .fromKey(originalKey)
                .toKey(transposedKey)
                .down(capo)
                .toString();
          });
        });
      });
      this.$set(this.fetchedSong, "sections", newSections);
    },
    fetchActivities(options) {
      if (!options) options = {};
      if (options.reset) {
        this.page = 0;
      }

      if (!this.isLoadingActivities) {
        this.isLoadingActivities = true;
        let songId = this.$route.params.song_id || this.song.id;
        this.page += 1;
        this.$http
            .get(`/api/activites/sur/Song/${songId}/page/${this.page}`)
            .then((response) => {
              if (options.reset) {
                this.activities = response.data;
                this.noMoreActivities = false;
              } else {
                this.activities = this.activities.concat(response.data);
              }
              if (response.data.length == 0) {
                this.noMoreActivities = true;
              }
              this.isLoadingActivities = false;
            });
      }
    },
    postsCreate() {
      if (!this.isSavingActivity) {
        this.isSavingActivity = true;
        this.post.isDraft = false;
        let post = decamelizeKeys(this.post);
        let songId = this.$route.params.song_id || this.song.id;
        this.$http
            .post(`/api/publications.json`, {
              post: post,
            })
            .then((response) => {
              this.post = this.blankPost();
              this.isSavingActivity = false;
              this.fetchActivities({reset: true});
            });
      }
    },
    resolveNoteOffset() {
      // Resolve noteOffsetMultiplier
      let songLines = this.$refs["song-lines"];
      if (songLines && songLines.length > 0) {
        let songLine = songLines[0];
        var style = window
            .getComputedStyle(songLine, null)
            .getPropertyValue("font-size");
        var fontSize = parseFloat(style);
        this.noteOffsetMultiplier = 0.4375 * fontSize;
        // console.log("noteOffsetMultiplier", this.noteOffsetMultiplier);
      }
    },
    scroll() {
      EventBus.$on("scroll", (scrollTop) => {
        let bottomOfWindow =
            scrollTop + window.innerHeight >=
            document.documentElement.offsetHeight - 150;
        if (
            bottomOfWindow &&
            !this.isLoadingActivities &&
            !this.noMoreActivities
        ) {
          this.fetchActivities();
        }
      });
    },
    updateSongPreferences(usp) {
      let updateKeys = ["favoriteKey", "favoriteCapo"];
      for (let key of updateKeys) {
        if (usp[key] != null) this.$set(this.fetchedSong, key, usp[key]);
      }
    },
    // Prevents from triggering selectedKey watcher before song is initialized
    initializeSong() {
      this.transposeFetchedSong();
      setTimeout(() => {
        this.isInitializingSong = false;
      }, 810);
    },
    attachMedia(media) {
      this.post.mediasIds.push(media.id);
    },
    detectUrlAnchor(to) {
      if (to.hash) {
        const anchors = Object.fromEntries(
            to.hash
                .replace("#", "")
                .split(":")
                .reduce((resultArray, item, index) => {
                  const chunkIndex = Math.floor(index / 2);
                  if (!resultArray[chunkIndex]) {
                    resultArray[chunkIndex] = []; // start a new chunk
                  }
                  resultArray[chunkIndex].push(item);
                  return resultArray;
                }, [])
        );

        // Accepted anchors are :
        // #tab:lyrics
        if (anchors) {
          if (anchors.tab && ["lyrics"].includes(anchors.tab))
            this.tab = anchors.tab;
        }
      }
    },
    loadSong(onMounted) {
      if (
          !this.fetchedSong?.sections ||
          (!onMounted && this.fetchedSong?.id != this.$route.params.song_id)
      ) {
        console.log("fetching song ... ", this.fetchedSong?.id != this.$route.params.song_id);
        this.fetchSong();
      } else {
        this.isInitializingSong = true;
        this.selectedKey = this.fetchedSong.lastKey || this.fetchedSong.preferenceKey || this.fetchedSong.key;
        this.selectedCapo = this.fetchedSong.lastCapo || this.fetchedSong.preferenceCapo || this.fetchedSong.capo || 0;
        this.initializeSong();
      }

      this.$store.commit("setUiStates", {
        hideMenus: false,
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    console.log("->>>beforeRouteUpdate");
    next();
    this.loadSong();
    this.detectUrlAnchor(to);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.detectUrlAnchor(to);
      vm.loadSong();
    });
  },
  mounted() {
    if (this.enablePrintMode) this.isOnPrintMode = true;
    this.loadSong(true);
  },
  computed: {
    currentUserIsCreator() {
      return this.fetchedSong?.createdBy?.id == this.currentUser?.id;
    },
    ...mapGetters(["currentUserHasRoles"]),
    ...mapState({
      currentUser: "currentUser",
      // 'isReady': (uiStates) => {
      //   console.log("uuu", uiStates.isReady)
      //   return uiStates.isReady
      // }
    }),
  },
  watch: {
    enablePrintMode(enablePrintMode) {
      if (enablePrintMode && !this.isOnPrintMode) {
        this.enablePrintMode = true;
      }
    },
    song(song) {
      if (!song?.sections) {
        this.fetchSong();
      } else {
        this.isInitializingSong = true;
        this.fetchedSong = song;
        this.selectedKey = song.lastKey || song.preferenceKey || song.key;
        this.selectedCapo = song.lastCapo || song.preferenceCapo || song.capo || 0;
        this.initializeSong();
        this.post = this.blankPost();
      }
    },
    selectedKey: debounce(function (newKey) {
      if (!this.isInitializingSong) {
        this.$emit("selectedKey", newKey);
        this.fetchSong(newKey);
      }
    }, 800),
    selectedCapo: debounce(function (newCapo) {
      if (!this.isInitializingSong) {
        this.$emit("selectedCapo", newCapo);
        this.fetchSong(this.selectedKey, newCapo);
      }
    }, 800),
    tab(tab) {
      switch (tab) {
        case "sharedLinks":
          this.fetchActivities({reset: true});
          break;
        default:
      }
    },
    fetchedSong(fetchedSong) {
      if (fetchedSong) {
        this.resolveNoteOffset();
      }
    },
  },
};
</script>
<style lang="scss">
#song-actions {
  img {
    max-height: 30px;
  }
}

#song-tab {
  .v-tabs-items {
    background: transparent !important;
  }

  .v-tabs-slider-wrapper {
    z-index: 1;
  }
}

.on-print-mode {
  .row {
    display: block;
    flex-wrap: nowrap;
    flex: none;
  }

  .v-card__text {
    background-color: white !important;
  }
}
</style>
<style lang="scss" scoped>
.on-print-mode {
  // .page-break {
  //   height: 20px;
  //   background-color: aqua;
  // }
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  //background-color: pink;
  width: 760px;
  zoom: 130%;

  .song-header {
    padding-left: 24px;
  }

  h1 {
    font-size: 27px;
  }

  .song-print-key {
    position: absolute;
    //left: 720px;
    right: 50px;
    height: 100px;
    z-index: 1000;
    text-align: center;

    .key {
      background-color: #fe490d;
      color: white;
      font-weight: bold;
    }
  }

  .adf-logo {
    position: absolute;
    right: 40px;
    height: 100px;
    z-index: 1000;
    bottom: 0;
  }
}

#dropzone {
  width: 100%;
  min-height: 40px;
  padding: 10px;

  > .dz-default.dz-message {
    margin: 0;
    padding: 0;
  }
}

.embed-mode {
  .side-song-info {
    background: #f7f7f7;
    margin-left: -10px;
    border-top-right-radius: 20px;
    margin-top: 15px;
    margin-bottom: 35px;
  }
}

#song-tab-contents {
  // background: transparent;
  &.print-mode {
    //  background: red !important;
  }
}

.right-widget {
  position: sticky;
  top: 65px;

  a {
    text-decoration: none;
  }
}

.watermark {
  font-size: 11px;
}

.print-mode, .on-print-mode {
  .song-content {
    .song-section {
      width: 860px;

      .song-line {
        .note {
          height: 28px;
          padding-top: 4px;
          line-height: 25px;
        }

        //font-size: 28px !important;
        //height: 60px !important;
      }
    }
  }
}

.export-rule {
  //display: none;
  position: absolute;
  background-color: red;
  left: 0;
  top: 0;
  width: 20px;
  height: 1060px;
  z-index: 10000;
}

.song-content {
  margin-top: -30px;

  .song-section {
    margin-top: 30px;
    border-left: 3px solid #eee;
    padding-left: 10px;

    .section-name {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      font-weight: 500;
      display: inline-block;
      background: #eee;
      padding: 5px;
      margin-left: -10px;
      position: sticky;
      top: 70px;
      z-index: 1;
    }

    &.with-notes:first-child {
      // margin-top: -10px;
    }

    .song-line {
      font-size: 19px;
      // background: pink;
      // border-bottom: 1px solid red;
      .line-notes {
        position: relative;
        margin: 15px 0 0 0;
        height: 30px;
        // background: purple;
        .note {
          position: absolute;
          background: #f44336;
          padding: 3px 5px;
          border-radius: 3px;
          color: white;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .attach-zone {
    margin-left: 0px !important;
  }
  .v-application--is-ltr
  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
    margin-left: 0;
  }
}
</style>
